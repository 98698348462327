import React from 'react'
import './Footer.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  return (
    <div>
      <div  className="course_container flt">
            <div  className="container">
                <div  className="row">
                    <div  className="col-sm-6">
                        <div  className="course_box flt">
                            <div  className="course_icon">
                                <img src={require("../assets/assets/images/course-icon1.png")} alt="" />
                            </div>
                            <div  className="course_txt">Summer Course Date :
                                <span>  07 - 18 July 2025</span>
                            </div>
                        </div>
                        <div  className="course_box flt">
                            <div  className="course_icon">
                                <img src={require("../assets/assets/images/course-icon2.png")} alt="" />
                            </div>
                            <div  className="course_txt">Last Date to Apply :
                                <span>  30th April 2025 (Extended Deadline)</span>
                            </div>
                        </div>
                    </div>
                    <div  className="col-sm-6">
                        <div  className="course_box flt">
                            <div  className="course_icon">
                                <img src={require("../assets/assets/images/course-icon3.png")} alt="" />
                            </div>
                            <div  className="course_txt">Fee for the Course :
                                <span> 955 USD</span>
                            </div>
                        </div>
                        <div  className="course_box flt">
                            <div  className="course_icon">
                                <img src={require("../assets/assets/images/course-icon4.png")} alt="" />
                            </div>
                            <div  className="course_txt">Certification :
                                <span> 06 ECTS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  className="footer_container flt">
            <div  className="container">
                <div  className="row">
                    <div  className="col-sm-4">
                        <div  className="footer_box flt">
                            <div  className="footer_logo flt">
                                <img src="./logo.png" alt="logo" />
                            </div>
                            <div  className="footer_address flt">
                                <i  className="fas fa-map-marker-alt"></i> Office of International Affairs &
                                Collaborations<br />
                                Manipal Academy of Higher Education<br />
                                Madhav Nagar, Manipal – 576104<br />
                                Karnataka, INDIA
                            </div>
                        </div>
                    </div>
                    <div  className="col-sm-3">
                        <div  className="footer_box flt">
                            <div  className="footer_head flt">Program Duration</div>
                            <ul  className="footer_list flt">
                                <li>40 hours of Academics</li>
                                <li>40 hours of Cultural Immersion</li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-sm-2">
                        <div  className="footer_box flt">
                            <div  className="footer_head flt">Quick Links</div>
                            <ul  className="footer_list flt">
                                <li><a href="https://summer.manipal.edu/">Home</a></li>
                                <li><a href="https://summer.manipal.edu/index.php/about-us/">About Us</a></li>
                                <li><a href="https://summer.manipal.edu/index.php/contact-us/">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-sm-3">
                        <div  className="footer_box flt">
                            <div  className="footer_head flt">Contact Us</div>
                            <ul  className="footer_list flt">
                                <li><a href="#">
                                  <EmailIcon />
                                 summer.school@manipal.edu</a></li>
                                <li><a href="#">
                                <PhoneIcon />
                                 +91 820 2923441/43</a></li>
                                <li><a href="#">
                                <WhatsAppIcon />
                                 +91 6364919433</a></li>
                            </ul>
                            <ul  className="footer_media flt">
                                <li><a href="https://twitter.com/OIAC_MAHE?t=dIpa7U9rqu9pbEd6RxAmcQ&s=09">
                                    <TwitterIcon />
                                </a></li>
                                <li><a href="https://instagram.com/oiac_mahe?igshid=YmMyMTA2M2Y=">
                                    <InstagramIcon />
                                </a></li>
                                <li><a href=" https://www.facebook.com/muoiac">
                                    <FacebookIcon />
                                </a></li>
                                <li><a href="https://www.linkedin.com/in/office-of-international-affairs-and-collaborations-mahe-manipal-93b338254">
                                    <LinkedInIcon />
                                </a></li>
                                <li><a href="#">
                                   <YouTubeIcon />
                                </a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div  className="copyright_container flt">
            <div  className="container">
                <div  className="copyright flt">
                    <div  className="copy_left">©2024 Manipal Academy of Higher Education</div>
                    <div  className="copy_right">Designed & Developed By <a href="https://www.chipsyservices.com/" target="_blank">Chipsy IT Services Pvt. Ltd.</a></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
