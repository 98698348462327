import React from 'react'
import './Home.css'
import HomeHook from './HomeHook'
import Select from 'react-select';
import moment from 'moment';
import 'react-phone-input-2/lib/style.css'
import '@react-pdf-viewer/core/lib/styles/index.css';
import { format } from 'date-fns'
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";


function App() {
    const {fileHandler1,
        fileHandler2,
        fileHandler3,
        //fileHandler4,
        imagePreviewUrl1,
        imagePreviewUrl2,
        imagePreviewUrl3,
        //imagePreviewUrl4,
        handleSelectChange,
        handleChange,
        handleDate,
        selectedValue,
        firstname,
        lastname,
        email,
        gender,
        date,
        address,
        country,
        nationality,
        university,
        pursuingYear,
        program,
        emergencyname,
        emergencyphone,
        emergencyemail,
        emergencyaddress,
        img1,img2,img3,
        validFile,limitFile,
        handleSubmit,
        formsubmit,
        course,
        isOpened,
        back_button,
        checkboxhandler,
        submitted,
        list,
        checkbox,
        formsubmitted,
        boxsubmit,
        validemail,
        emailValid,
        popup, popupClose
    } = HomeHook()

  return (
       course  &&
        <> 
        {  !isOpened &&  !popup && 
        <div className="container">
        <div className="form1 flt mb-5">
        <form>
          <h3>Registration Form </h3>
          <div className='row'>
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>First Name <span className="star">*</span></label>
              <input type="text" name="firstname" value={firstname} className={'form-control flt' + (formsubmitted && !firstname ? ' is-invalid' : '')} placeholder='First name' onChange={handleChange}/>              
               {formsubmitted && !firstname &&
                     <div className="invalid-feedbacks"> First name is required</div>
               }
           </div>
          </div>
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Last Name <span className="star">*</span></label>
              <input type="text" name="lastname" value={lastname} className={'form-control flt' + (formsubmitted && !lastname ? ' is-invalid' : '')} placeholder='Last name' onChange={handleChange}/>
              {formsubmitted && !lastname &&
                    <div className="invalid-feedback">Last name is required</div>
               }
           </div>
          </div>    
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Email<span className="star">*</span></label>
              <input type="email" name="email" value={email} className={'form-control flt' + (formsubmitted && !email  ? ' is-invalid' : '')} placeholder='Email' onChange={handleChange}
              />
              {formsubmitted && !email &&
                     <div className="invalid-feedback"> Email is required</div>
               }
               {!emailValid && email &&
                    <div className="invalid-feedbacks">Enter valid email</div>
                }
           </div>
          </div>
          <div className='col-sm-4'>
          <div className="form-group flt">
            <label>Gender <span className="star">*</span></label>
              <select name="gender" onChange={handleChange} value={gender} className={'form-control flt' + (formsubmitted && !gender ? ' is-invalid' : '')}>
                <option value="" hidden>Select Gender</option>
                <option >Male</option>
                <option >Female</option>
                <option >Others</option>
              </select>
              {formsubmitted && !gender &&
                     <div className="invalid-feedback">Gender is required</div>
               }
         </div>
          </div>  
          <div className='col-sm-4'>
          <div className="form-group flt">
             <label>Date of Birth<span className="star">*</span></label>
             {/* <input type="date" id="birthday" name="date" value={date} className={'form-control flt' + (formsubmitted && !date ? ' is-invalid' : '')} onChange={handleDate}
                max={moment().format("YYYY-MM-DD")} 
             /> */}
             {/* <ModernDatepicker
                    date={date}
                    format={'DD-MM-YYYY'}
                    icon={false}
                    onChange={(e)=>handleDate(e)}
                    placeholder={'MM-DD-YYYY'}
                    // value={date}
                    className={'form-control flt' + (formsubmitted && !date ? ' is-invalid' : '')}
             /> */}
             <Datetime
                name="date"
                dateFormat="DD-MM-YYYY"
                onChange={(e)=>handleDate(e)}
                value={date}
                timeFormat={false}
                inputProps={{
                  placeholder: "Select Date of Birth",
                }}
                className={'flt' + (formsubmitted && !date ? ' is-invalid' : '')}
              />
             {formsubmitted && !date &&
                    <div className="invalid-feedbacks">Date of birth is required</div>
             }
           </div>
          </div> 
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Address<span className="star">*</span></label>
              <input type="text" name="address" value={address} onChange={handleChange} className={'form-control flt' + (formsubmitted && !address ? ' is-invalid' : '')} placeholder='Address'/>
              {formsubmitted && !address &&
                     <div className="invalid-feedback">Address is required</div>
               }
           </div>
          </div>  
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Home Country<span className="star">*</span></label>
              <input type="text" name="country" value={country} onChange={handleChange} className={'form-control flt' + (formsubmitted && !country ? ' is-invalid' : '')} placeholder='Home Country'/>
              {formsubmitted && !country &&
                     <div className="invalid-feedback">Home Country is required</div>
               }
           </div>
          </div> 
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Nationality<span className="star">*</span></label>
              <input type="text" name="nationality" value={nationality} onChange={handleChange} className={'form-control flt' + (formsubmitted && !nationality ? ' is-invalid' : '')} placeholder='Nationality'/>
              {formsubmitted && !nationality &&
                     <div className="invalid-feedback">Nationality is required</div>
               }
           </div>
          </div> 
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Home University<span className="star">*</span></label>
              <input type="text" name="university" value={university} onChange={handleChange} className={'form-control flt' + (formsubmitted && !university ? ' is-invalid' : '')} placeholder='Home university'/>
              {formsubmitted && !university &&
                     <div className="invalid-feedback">Home University is required</div>
               }
           </div>
          </div>  
          <div className='col-sm-4'>
          <div className="form-group flt">
            <label>Currently Pursuing<span className="star">*</span></label>
            <input type="text" name="pursuingYear"value={pursuingYear} onChange={handleChange} className={'form-control flt' + (formsubmitted && !pursuingYear ? ' is-invalid' : '')} placeholder='Currently Pursuing'/>
              {/* <select name="pursuingYear" value={pursuingYear} onChange={handleChange} className={'form-control flt' + (formsubmitted && !pursuingYear ? ' is-invalid' : '')}>
                <option >Currently Pursuing</option>
                <option >2020</option>
                <option >2021</option>
                <option >2022</option>
              </select> */}
              <div className="program">(Example: Undergraduate, Postgraduate, Others)</div>
              {formsubmitted && !pursuingYear &&
                     <div className="invalid-feedback">Currently Pursuing is required</div>
               }
         </div>
          </div>  
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Program/major at Home University<span className="star">*</span></label>
              <input type="text" name="program" value={program} onChange={handleChange} className={'form-control flt' + (formsubmitted && !program ? ' is-invalid' : '')} placeholder='Program/major at Home university'/>
              <div className="program">(Example: Physics,Medicine,etc...)</div>
              {formsubmitted && !program &&
                     <div className="invalid-feedback">Program/major at Home University is required</div>
               }
           </div>
          </div> 
          </div>
      </form>

      <form>
          <h3>Emergency Contact Reference</h3>
          <div className='row'>
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Name <span className="star">*</span></label>
              <input type="text"  name="emergencyname" value={emergencyname} onChange={handleChange}  className={'form-control flt' + (formsubmitted && !emergencyname ? ' is-invalid' : '')} placeholder='Name'/>
              {formsubmitted && !emergencyname &&
                     <div className="invalid-feedback">Name is required</div>
               }
           </div>
          </div>
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Phone <span className="star">*</span></label>
              <input type="text" name="emergencyphone" value={emergencyphone} onChange={handleChange}  className={'form-control flt' + (formsubmitted && !emergencyphone ? ' is-invalid' : '')} placeholder='Phone'/>
              {formsubmitted && !emergencyphone &&
                     <div className="invalid-feedback">Phone number is required</div>
               }
           </div>
          </div>
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>email <span className="star">*</span></label>
              <input type="email" name="emergencyemail" value={emergencyemail} onChange={handleChange} className={'form-control flt' + (formsubmitted && !emergencyemail ? ' is-invalid' : '')} placeholder='Email'/>
              {formsubmitted && !emergencyemail &&
                     <div className="invalid-feedback">Email is required</div>
               }
               {!validemail && emergencyemail &&
                    <div className="invalid-feedbacks">Enter valid email</div>
                }
           </div>
          </div>    
          <div className='col-sm-4'>
          <div className="form-group flt">
              <label>Address<span className="star">*</span></label>
              <input type="text" name="emergencyaddress" value={emergencyaddress} onChange={handleChange} className={'form-control flt' + (formsubmitted && !emergencyaddress ? ' is-invalid' : '')} placeholder='Address'/>
              {formsubmitted && !emergencyaddress &&
                     <div className="invalid-feedback">Address is required</div>
               }
           </div>
          </div>        
          </div>
      </form>

      <div className="cours flt">
            <label>Choose courses<span className="star">*</span></label>
              <Select
              //className='dropdown'
              placeholder='Select Courses'
              value={course.filter(obj=>selectedValue.includes(obj.label))}
              options={course}
              onChange={handleSelectChange}
              isMulti
              isClearable
              isOptionDisabled={() => selectedValue.length >= 5}
              // className="basic-multi-select"
              classNamePrefix="select"
              //name='selects'
              className={'dropdowns flt' + (formsubmitted && selectedValue.length>0 ? ' is-invalid' : '')}
              >
              </Select>
              <div className="note">(Note:  With individual themes having limited seats,  your selection will be notified based on the availability of seats in the order of your priority selected.)</div>  
              {formsubmitted && selectedValue.length===0 &&
                    <div className="invalid-feedbacks">Courses are required</div>
              }  
              <ul className="course_list flt">
                <li>
                  {list}
                </li>
              </ul>  
              {selectedValue.length===1 &&
                    <div className="note">You can select upto 5 courses as per your priority from the above dropdown.</div>
              }  
              <p className='fees'>Program fee is - $955</p>
      </div>
         <div className='mt-2 flt'>
            <h3 className='documents'>Documents Required <span className="image_format"> (File Format : .png / .jpg)</span></h3>
            <div className='row'>
            <div className='col-sm-3'>
               <label>Upload Your Image<span className="star">*</span></label>
               <div className="image_preview flt mb-4">
                    {imagePreviewUrl1 &&
                        <img src={imagePreviewUrl1} alt=""/>
                    }
              </div>
                <input accept="image/*" type="file" id="select-image"
                    onChange={fileHandler1} className={'form-control flt' + (formsubmitted && !imagePreviewUrl1 ? ' is-invalid' : '')}
                />
                {formsubmitted && !img1 &&
                    <div className="invalid-feedback">Profile photo is required</div>
                }
                {formsubmitted && img1 && !validFile &&
                    <div className="invalid-feedback">Please upload valid image</div>
                }
            </div>
            <div className='col-sm-3'>
               <label>Upload Valid student ID<span className="star">*</span></label>
               <div className="image_preview flt mb-4">
                    {imagePreviewUrl2 &&
                        <img src={imagePreviewUrl2} alt=""/>
                    }
              </div>
                <input accept="image/*" type="file" id="select-image"
                    onChange={fileHandler2} className={'form-control flt' + (formsubmitted && !imagePreviewUrl2 ? ' is-invalid' : '')}
                />
                 {formsubmitted && !img2 &&
                    <div className="invalid-feedback">student ID is required</div>
                }
                {formsubmitted && img2 && !validFile &&
                    <div className="invalid-feedback">Please upload valid image</div>
                }
            </div>
            <div className='col-sm-3'>
               <label>Upload Passport scan<span className="star">*</span></label>
               <div className="image_preview flt mb-4">
                    {imagePreviewUrl3 &&
                        <img src={imagePreviewUrl3} alt=""/>
                    }
              </div>
                <input accept="image/*" type="file" id="select-image"
                    onChange={fileHandler3} className={'form-control flt' + (formsubmitted && !imagePreviewUrl3 ? ' is-invalid' : '')}
                />
                 {formsubmitted && !img3 &&
                    <div className="invalid-feedback">Passport is required</div>
                }
                {formsubmitted && img3 && !validFile &&
                    <div className="invalid-feedback">Please upload valid image</div>
                }
            </div>
            {/* <div className='col-sm-3'>
               <label>Upload Bonafide certificate<span className="star">*</span></label>
               <div className="image_preview flt mb-4">
                    {imagePreviewUrl4 &&
                        <img src={imagePreviewUrl4} alt=""/>
                    }
              </div>
                <input accept="image/*" type="file" id="select-image"
                    onChange={fileHandler4} className={'form-control flt' + (formsubmitted && !imagePreviewUrl4 ? ' is-invalid' : '')}
                />
                 {formsubmitted && !img4 &&
                    <div className="invalid-feedback">Bonafide certificate is required</div>
                }
                {formsubmitted && img4 && !validFile &&
                    <div className="invalid-feedback">Please upload valid image</div>
                }
            </div> */}
            </div>
         </div>
         <div className='submit flt'>
          <button onClick={formsubmit} >Next</button>           
         </div> 
   </div>
   </div> }
   
   {isOpened && !popup && 
   <>
    <div className='container mt-4'>
      <p>Please review your data before submitting...</p>
      <div className='row users'>
        <div className="col-12 sm-12">
        <h4 className="blog_head flt">Personal Details</h4>
            <div className='personal_details flt'>              
              <ul className="user_list flt">
                    <li> <d>First Name</d>  <span>: {firstname}</span> </li>
                    <li> <d>Last Name </d> <span>: {lastname}</span></li>
                    <li> <d>Email </d> <span>: {email}</span> </li>
                    <li> <d>Gender </d> <span>: {gender}</span> </li>
                    <li> <d>Date of birth </d> <span>: {moment(date).format('DD-MM-YYYY')}</span>  </li>
                    <li> <d>Address </d> <span>: {address}</span> </li>
                    <li> <d>Country </d> <span>: {country}</span> </li>
                    <li> <d>Nationality </d> <span>: {nationality}</span> </li>
                    <li> <d>Home University </d> <span>: {university}</span> </li>
                    <li> <d>Currently Pursuing </d> <span>: {pursuingYear}</span> </li>
                    <li> <d>Program / major at Home university </d> <span>: {program}</span> </li>
              </ul>
                <div className="user_id mb-4">
                        {imagePreviewUrl1 &&
                           <img src={imagePreviewUrl1} alt=""/>
                        }
                </div>
            </div>
              <h4 className="mt-4 blog_head flt">Emergency Contact Details</h4>
              <ul className="user_list flt">
                    <li> <d>Name </d> <span>: {emergencyname}</span> </li>
                    <li> <d>Phone </d> <span>: {emergencyphone}</span> </li>
                    <li><d>Email</d> <span>: {emergencyemail}</span>  </li>
                    <li> <d>Address </d> <span>: {emergencyaddress}</span> </li>
              </ul>
              <h4 className="blog_head flt">Courses chosen as per your priority</h4>

              <ul className="user_list flt">       
                  <li>{list}
                   </li>   
              </ul>
              <h4 className="blog_head flt">Documents</h4>
              <div className='row'>
                <div className='col-sm-4'>
                <div className='blog_title flt'> University Id card</div>
                <div className="image_preview flt mb-4">
                    {imagePreviewUrl2 &&
                        <img src={imagePreviewUrl2} alt=""/>
                    }
              </div>
                </div>

                <div className='col-sm-3'>
                <div className='blog_title flt'>Passport Scan</div>
                <div className="image_preview flt mb-4">
                    {imagePreviewUrl3 &&
                        <img src={imagePreviewUrl3} alt=""/>
                    }
              </div>
                </div>

                {/* <div className='col-sm-3'>
                <div className='blog_title flt'> Bonafide certificate</div>
                <div className="image_preview flt mb-4">
                    {imagePreviewUrl4 &&
                        <img src={imagePreviewUrl4} alt=""/>
                    }
              </div>
                </div> */}
              </div>
         </div>
       </div>
       {/* <div className="mt-5 dropdownss flt">
           <button className="button dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
           Included in the Fee (for the program duration only)
           </button>
           <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <li className="dropdown-item">Accommodation at University Guest House (on a twin sharing basis)</li>
               <li className="dropdown-item">Breakfast, Lunch and Dinner at NIH Food Court</li>
               <li className="dropdown-item">Internal Transport</li>
               <li className="dropdown-item">Medicare and Wi-Fi </li>
               <li className="dropdown-item">Airport transfer (Mangalore International airport)</li>
               <li className="dropdown-item">Welcome kit</li>
          </ul>
        </div> */}
        <div className="mt-5">
            <h6 className='feeLabel'>Included in the Fee (for the program duration only)</h6>
            <ul className="feeInclude">
               <li >Accommodation at University Guest House (on a twin sharing basis)</li>
               <li>Breakfast, Lunch and Dinner at NIH Food Court</li>
               <li >Internal Transport</li>
               <li >Medicare and Wi-Fi </li>
               <li >Airport transfer (Mangalore International airport)</li>
               <li >Welcome kit</li>
          </ul>
        </div>
       <div className='checkbox flt'>
          <label className='mt-3'>Declarations</label>
          <input
          type="checkbox"
          checked={checkbox}
          onChange={checkboxhandler}
          className={'mx-3' + (submitted && !checkbox ? ' is-invalid' : '')}
        />
           {submitted && !checkbox &&
                <div className="invalid-feedback">You must confirm it before submitting the form</div>
           }
           <ul className='declarations'>
            <li>I hereby declare that all the particulars stated in the application form are true to the best of my knowledge. </li>
            <li>I shall abide by all the rules and regulations of the University during my stay on campus</li>
            <li>I affirm that I fulfil the eligibility requirements for the program applied</li>
            <li>I understand that the fee remitted towards the program will not be refunded</li>
            <li>I am well aware that the accommodation provided is a smoke-free/alcohol-free zone. I shall not violate the hostel rules and return to the residency within the curfew time limit. </li>
            <li>I fully agree that jurisdiction, if any, for dispute settlement shall be at Udupi Court, Karnataka, India only</li>
           </ul>
         </div>
         <div className='submit flt'>
           <button onClick={back_button} className='mx-3 btn btn-outline-secondary'>Back</button>
            <button onClick={handleSubmit} className="my-2">Submit</button>
         </div>
      </div>
      </>
    }

    {
        popup &&
        <>
        <div className='popup-box flt'>
         <div className='box flt'>
        <div className='imgg flt'>
          <img src="./logo.png" alt=""></img>
        </div>

        <div className='head  flt'><h2>Thank You!</h2></div>
        <div className='para flt'><p>Dear Applicant, Your Application is successfully submitted</p></div>
        <div className='para2 flt'>
          Thank you for registering with MAHE Manipal for MISS-2024. A confirmation mail will be sent to your registered email ID. Kindly keep a tab on your email for updates on your selection and details thereafter.
        </div>
        <div className='mt-4 sub_btn flt'><button onClick={() => { window.location.href = "https://summer.manipal.edu/"} }>OK</button></div>
      </div>
    </div>
        </>
    }
  </>
   
  )

}

export default App
